import React, { useState } from "react";
import { DeltaStatic } from "quill";
import { Form, Tabs } from "antd";
import { DescriptionDisclosure } from "../../../../types/model/promotion";
import styles from "./DescriptionAndDisclosureContent.module.scss";
import { isDeltaEmpty } from "../../../../utils/richTextEditor";
import { DESCRIPTION_MAX_LENGTH } from "../../../products/Disclosure/common";
import DescriptionContentInput from "../../../products/DescriptionTemplate/DescriptionDetails/DescriptionContentInput";
import DescriptionContentDescription from "../../../products/DescriptionTemplate/DescriptionDetails/DescriptionContentDescription";
import { LocaleType, supportLocalTabs } from "../../../../utils/locale";
import { ContentBase } from "../../../../types/model/description";

interface DescriptionAndDisclosureContentProps {
  editable: boolean;
  descriptionDisclosure?: DescriptionDisclosure;
}

export default function DescriptionAndDisclosureContent({
  editable,
  descriptionDisclosure,
}: DescriptionAndDisclosureContentProps) {
  const [descriptionContents, setDescriptionContents] = useState<ContentBase[]>(
    descriptionDisclosure?.description || []
  );
  const [disclosureContents, setDisclosureContents] = useState<ContentBase[]>(descriptionDisclosure?.disclosure || []);

  const handleDescriptionChange = (local: LocaleType, value: DeltaStatic) => {
    const updatedContents = descriptionContents.map((content) =>
      content.locale === local ? { ...content, content: value } : content
    );
    setDescriptionContents(updatedContents);
  };

  const handleDisclosureChange = (local: LocaleType, value: DeltaStatic) => {
    const updatedContents = disclosureContents.map((content) =>
      content.locale === local ? { ...content, content: value } : content
    );
    setDisclosureContents(updatedContents);
  };

  return (
    <div className={styles.tabCardContainer}>
      <Tabs type="card">
        {supportLocalTabs?.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            <Form.Item
              label="Description Content"
              name={`${item.locale}DescriptionContent`}
              rules={[
                {
                  required: false,
                  transform: (value) => (isDeltaEmpty(value) ? "" : JSON.stringify(value)),
                  type: "string",
                  max: DESCRIPTION_MAX_LENGTH,
                },
              ]}
            >
              <DescriptionContentInput
                onChange={(value) => handleDescriptionChange(item.locale, value)}
                editable={editable}
                value={descriptionContents.find(({ key }) => key === item.locale)?.content || ({} as DeltaStatic)}
              />
            </Form.Item>
            <Form.Item
              label="Disclosure Content"
              name={`${item.locale}DisclosureContent`}
              rules={[
                {
                  required: false,
                  transform: (value) => (isDeltaEmpty(value) ? "" : JSON.stringify(value)),
                  type: "string",
                  max: DESCRIPTION_MAX_LENGTH,
                },
              ]}
            >
              <DescriptionContentInput
                onChange={(value) => handleDisclosureChange(item.locale, value)}
                editable={editable}
                value={disclosureContents.find(({ key }) => key === item.key)?.content || ({} as DeltaStatic)}
              />
            </Form.Item>
            <DescriptionContentDescription isPromotionContentDescription={true} isTemplateDescription={true} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}
