import {
  SubscriptionModel,
  SubscriptionResponse,
  SubscriptionSource,
  SubscriptionUpsellDowngradeListItem,
  SubscriptionUpsellDowngradeListResponse,
} from "../model/subscriptions";

export function transformSubscriptions(dto: SubscriptionResponse): SubscriptionModel {
  const subscriptionModel: SubscriptionModel = {
    title: dto.title,
    status: dto.status,
    paymentMethod: {
      cardType: dto.payment_method.card_type,
      account: dto.payment_method.account,
      billingAddress: {
        country: dto.payment_method.billing_address.country,
      },
    },
    source: dto.source,
    subscriptionId: dto.subscription_id,
    startDate: dto.start_date,
    endDate: dto.next_billing?.date ? undefined : dto.end_date,
    pauseStartDate: dto.paused_start_date,
    pid: dto.pid,
    actions: [],
  };

  if (dto.status === "Cancelled") {
    subscriptionModel.status = "Canceled";
  }

  if (dto.free_trail_end_date) {
    subscriptionModel.status = "Free Trial";
  }

  if (dto.grace_period_end_date) {
    subscriptionModel.endDate = dto.grace_period_end_date;
    subscriptionModel.status = "Grace Period";
  }

  if (dto.next_billing) {
    subscriptionModel.nextBilling = {
      date: dto.next_billing.date,
      amount: dto.next_billing.amount,
      currencySymbol: dto.next_billing.currency_symbol,
    };
  }

  subscriptionModel.actions = getActionsByStatusAndSource(subscriptionModel.status, subscriptionModel.source);

  return subscriptionModel;
}

const getActionsByStatusAndSource = (status: string, source: SubscriptionSource): string[] => {
  const statusActionsMapping: {
    [status: string]: { [source: string]: string[] };
  } = {
    Active: {
      [SubscriptionSource.eCom]: [
        "extendBillingDate",
        "applyPromoCode",
        "cancel",
        "pause",
        "upsell",
        "downgrade",
        "switchPlan",
      ],
      [SubscriptionSource.Naviga]: ["cancel"],
    },
    "Free Trial": {
      [SubscriptionSource.eCom]: ["extendBillingDate", "applyPromoCode", "cancel", "upsell", "downgrade", "switchPlan"],
      [SubscriptionSource.Naviga]: ["cancel"],
    },
    "Pending Cancel": {
      [SubscriptionSource.eCom]: [] as string[],
      [SubscriptionSource.Naviga]: [] as string[],
    },
    Canceled: {
      [SubscriptionSource.eCom]: ["restart"],
      [SubscriptionSource.Naviga]: [] as string[],
    },
    "Pending Pause": {
      [SubscriptionSource.eCom]: ["cancel"],
      [SubscriptionSource.Naviga]: ["cancel"],
    },
    Paused: {
      [SubscriptionSource.eCom]: ["resume", "cancel"],
      [SubscriptionSource.Naviga]: ["cancel"],
    },
    "Grace Period": {
      [SubscriptionSource.eCom]: ["cancel"],
      [SubscriptionSource.Naviga]: ["cancel"],
    },
  };

  if (statusActionsMapping[status] && statusActionsMapping[status][source]) {
    return statusActionsMapping[status][source];
  } else {
    return [];
  }
};

export function transformUpsellDowngradeItem(
  dto: SubscriptionUpsellDowngradeListResponse
): SubscriptionUpsellDowngradeListItem {
  return {
    pid: dto.pid,
    planTitle: dto.plan_title,
    basePrice: {
      id: dto.base_price.id,
      name: dto.base_price.name,
    },
    billingPlan: dto.billing_plan,
  };
}
