import moment from "moment";
import { ColumnType } from "antd/lib/table";
import { DATETIME_FORMAT, EMPTY, ENTITLEMENT_SOURCE_MAP, ENTITLEMENT_STATUS_MAP } from "constants/common";
import Icon from "@ant-design/icons/lib/components/Icon";
import { EntitlementModel } from "types/model/entitlement";
import { Tooltip, Typography } from "antd";
import { LongArrowup } from "assets/icons";
import Actions from "./Actions";

const { Text } = Typography;

function renderLanguageName(languageName: string, entity: EntitlementModel) {
  if (entity.isUpgradedToAllAccess) {
    return (
      <Tooltip title="Upgraded from premium subscription to all access" overlayInnerStyle={{ width: "max-content" }}>
        <Icon component={LongArrowup} />
        <Text>{languageName}</Text>
      </Tooltip>
    );
  }
  return languageName;
}

export const renderEntitlementType = (item: EntitlementModel) => {
  if (item.source === "ECOM_GIFT") {
    return "Gift Subscription";
  }
  return item.isSubscription ? "Subscription" : "One-shot";
};

const columnsB2C: ColumnType<EntitlementModel>[] = [
  {
    title: "Product ISBN",
    dataIndex: "isbn",
    width: "12%",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Product name",
    dataIndex: "productName",
    ellipsis: true,
    render: renderLanguageName,
  },
  {
    title: "Purchase type",
    width: "12%",
    ellipsis: true,
    render: (item: EntitlementModel) => renderEntitlementType(item),
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "9%",
    ellipsis: true,
    render: (value) => ENTITLEMENT_STATUS_MAP.get(value) || EMPTY,
  },
  {
    title: "Source",
    dataIndex: "source",
    width: "10%",
    ellipsis: true,
    render: (value) => ENTITLEMENT_SOURCE_MAP.get(value) || EMPTY,
  },
  {
    title: "Expiration date",
    dataIndex: "expirationDate",
    width: "12%",
    ellipsis: true,
    render: (value) => (value ? moment(value).format(DATETIME_FORMAT) : EMPTY),
  },
  {
    title: "Created at",
    dataIndex: "createdDate",
    width: "12%",
    ellipsis: true,
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: (a, b) => a.createdDate - b.createdDate,
    render: (value) => (value ? moment(value).format(DATETIME_FORMAT) : EMPTY),
  },
  {
    title: "Action",
    width: "8%",
    ellipsis: true,
    onCell: () => ({ onClick: (e) => e.stopPropagation() }),
    render: (record) => <Actions record={record} />,
  },
];

const columnsB2B: ColumnType<EntitlementModel>[] = columnsB2C.filter(
  (column) => !["Purchase type", "Action"].includes(column.title as string)
);

const columnConfig = {
  B2B: columnsB2B,
  B2C: columnsB2C,
};

export default columnConfig;
