export enum Connection {
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  USERNAME = "USERNAME",
}

export interface CustomerResponse {
  customerId: number;

  auth0Id: string;
  // Connection
  connection?: Connection;
  // Organization
  organization?: string;
  // Create At
  createdDate: number;
  // Customer Email
  email: string;
  // Customer Name
  name: string;

  supportResetPassword: boolean;
}

export interface CustomerInfoResponse {
  auth0Id: string;

  connection?: Connection;

  createdDate: number;

  customerGroup?: string;

  customerId: number;

  email: string;

  name: string;

  organization?: string;

  subUsers?: string[];

  supportResetPassword: boolean;

  rootUserName?: string;
}

interface Recipient {
  first_name: string;
  last_name: string;
  email: string;
}

interface GivenGift {
  id: string;
  title: string;
  delivery_date: string;
  recipient: Recipient;
  status: string;
}

interface ReceivedGift {
  title: string;
  giver: Recipient;
  redeem_date: string;
  subscription_start_date?: string;
  subscription_end_date?: string;
}

export interface GiftResponse {
  given_list: GivenGift[];
  received_list: ReceivedGift[];
}
