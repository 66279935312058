import React from "react";
import capitalize from "lodash/capitalize";
import moment from "moment";
import { Col, Divider, Form, Row, Space } from "antd";
import styles from "./index.module.scss";
import { DisplayInput } from "../../../../components";
import { DiscountType, PromotionDetailModel, PromotionStatus } from "../../../../types/model/promotion";
import { DATETIME_FORMAT_WITH_MINUTE, EMPTY } from "../../../../constants/common";
import CustomerDot from "../../../../components/CustomDot";
import DescriptionAndDisclosure from "../components/DescriptionAndDisclosure";
import DescriptionAndDisclosureContentView from "../components/DescriptionAndDisclosureContentView";

interface PromotionDetailsViewProps {
  promotionDetails?: PromotionDetailModel;
}

export default function PromotionDetailsView({ promotionDetails }: PromotionDetailsViewProps) {
  const [form] = Form.useForm();

  if (promotionDetails) {
    const {
      startAt,
      endAt,
      status,
      billingCycle,
      basePrices,
      discountType,
      percentageDiscount,
      countries = [],
      basePlan,
      giftPlan,
      descriptionDisclosures = [],
    } = promotionDetails;

    form.setFieldsValue({
      ...promotionDetails,
      timeRange: `${moment(startAt).local().format(DATETIME_FORMAT_WITH_MINUTE)} to ${moment(endAt)
        .local()
        .format(DATETIME_FORMAT_WITH_MINUTE)}`,
      status: capitalize(status),
      billingCycle: `1 to ${billingCycle}`,
      basePrices: basePrices?.map((basePrice) => basePrice.name).join(", "),
      basePlan: basePlan ? `${basePlan.pid} ${basePlan.title} / ${basePlan.billingPlanName}` : EMPTY,
      giftPlan: giftPlan?.title,
      discountType: capitalize(discountType),
      percentageDiscount: `${percentageDiscount}%`,
      countries: countries.map((country) => country.displayName).join(", "),
    });

    if (discountType === DiscountType.BUY_ONE_GIFT_ONE && descriptionDisclosures.length > 0) {
      const { description = [], disclosure = [] } = descriptionDisclosures[0];

      description?.forEach((item) => {
        form.setFieldsValue({
          [`${item.locale}DescriptionContent`]: item.content,
        });
      });
      disclosure?.forEach((item) => {
        form.setFieldsValue({
          [`${item.locale}DisclosureContent`]: item.content,
        });
      });
    }
  }

  return (
    <>
      <Form className={styles.promotionDetailContainer} form={form} layout="vertical">
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <Space direction="vertical" size={24} className={styles.formSections}>
            <header className={styles.subtitle}>Basic info</header>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item name="name" label="Promotion name">
                  <DisplayInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="status" label="Status">
                  <CustomerDot color={promotionDetails?.status || PromotionStatus.EXPIRED}>
                    <DisplayInput value={form.getFieldValue("status")} />
                  </CustomerDot>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item name="timeRange" label="Time Range">
                  <DisplayInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="code" label="Promotion code">
                  <DisplayInput />
                </Form.Item>
              </Col>
            </Row>
          </Space>
          <Divider />

          <Space direction="vertical" size={24} className={styles.formSections}>
            <header className={styles.subtitle}>Promotion Details</header>
            <Row>
              <Col span={6}>
                <Form.Item name="discountType" label="Promotion type">
                  <DisplayInput />
                </Form.Item>
              </Col>
            </Row>
            {promotionDetails?.discountType === DiscountType.PERCENTAGE && (
              <Row>
                <Col span={6}>
                  <Form.Item name="percentageDiscount" label="Discount">
                    <DisplayInput />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {promotionDetails?.discountType === DiscountType.AMOUNT && (
              <Row gutter={32}>
                <Col span={6}>
                  <Form.Item name="currencyCode" label="Currency">
                    <DisplayInput />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="amountDiscount" label="Amount">
                    <DisplayInput />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Divider />
            {promotionDetails?.discountType === DiscountType.BUY_ONE_GIFT_ONE ? (
              <>
                <Row>
                  <Col span={18}>
                    <Form.Item name="basePlan" label="Base plan">
                      <DisplayInput />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item name="giftPlan" label="Gift plan">
                      <DisplayInput />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={32}>
                  <Col span={6}>
                    <Form.Item name="isSubscription" label="Is subscription/all access">
                      <DisplayInput />
                    </Form.Item>
                  </Col>
                  {promotionDetails?.isSubscription && (
                    <>
                      <Col span={6}>
                        <Form.Item name="isRestrictToNew" label="Restrict to new subscription">
                          <DisplayInput />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item name="billingCycle" label="Applied billing cycle">
                          <DisplayInput />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
                <Divider />
                <Row gutter={32}>
                  <Col span={24}>
                    <Form.Item name="basePrices" label="Applied base price">
                      <DisplayInput />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={6}>
                    <Form.Item name="countries" label="Applied countries/regions">
                      <DisplayInput />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Space>
          <Divider />

          {promotionDetails?.discountType === DiscountType.BUY_ONE_GIFT_ONE && (
            <Space direction="vertical" size={24} className={styles.formSections}>
              <header className={styles.subtitle}>Description & Disclosure</header>
              <DescriptionAndDisclosureContentView />
            </Space>
          )}
        </Space>
      </Form>

      {promotionDetails &&
        promotionDetails.discountType !== DiscountType.BUY_ONE_GIFT_ONE &&
        promotionDetails.isSubscription && (
          <DescriptionAndDisclosure
            editable={false}
            descriptionDisclosures={promotionDetails.descriptionDisclosures || []}
          />
        )}
    </>
  );
}
